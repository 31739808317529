// src/controllers/nav-bar_controller.js
import { Controller } from "stimulus";
import { prettyDate } from "@shared/lib/time";

export default class extends Controller {
  static targets = ["publishedAtDate"];

  connect() {
    // console.log("%c[job Controller] CONNECTED", "color: #1976D2");
    const publishedAtTimestamp = this.data.get("publishedAtTimestamp");
    this.publishedAtDateTarget.textContent = `Posted on ${prettyDate(publishedAtTimestamp)}`; // doing this because we need to localize the times using javascript
  }
}

// src/controllers/nav-bar_controller.js
import { Controller } from "stimulus";
import $ from "cash-dom";

export default class extends Controller {
  static targets = ["mobileNav", "navIconMenu", "navIconClose"];

  connect() {
    console.log("%c[nav-bar Controller] CONNECTED", "color: #1976D2");
  }

  // disconnect() {
  //   console.warn('[LegacyNavBar Controller] dis-connected');
  // }

  showMobileNav(event) {
    event.preventDefault();
    $(this.mobileNavTarget).removeClass("header__actions--hidden");
    $(this.navIconMenuTarget).addClass("nav-icon--hidden");
    $(this.navIconCloseTarget).removeClass("nav-icon--hidden");
    // $("body").addClass("no-scroll");
  }

  hideMobileNav(event) {
    event.preventDefault();
    $(this.mobileNavTarget).addClass("header__actions--hidden");
    $(this.navIconMenuTarget).removeClass("nav-icon--hidden");
    $(this.navIconCloseTarget).addClass("nav-icon--hidden");
    // $("body").removeClass("no-scroll");
  }
}

import format from "date-fns/format";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import parseISO from "date-fns/parseISO";
import getTime from "date-fns/getTime";
import subHours from "date-fns/subHours";

export const hoursAgo = (hours) => {
  const result = subHours(new Date(), hours);
  return getTime(result);
};

export const timeAgoInWordsShort = (datetime) => {
  if (datetime !== undefined) {
    return formatDistanceToNow(datetime * 1000)
      .replace(/ mi.*/, " min")
      .replace(/ h.*/, "h")
      .replace(/ d.*/, "d")
      .replace(/ mo.*/, "M")
      .replace(/ y.*/, "Y")
      .replace(/about/, "");
  }

  return null;
};

export const timeAgoInWordsLong = (datetime) => {
  if (datetime !== undefined) {
    return formatDistanceToNow(datetime * 1000);
    // .replace(/ mi.*/, " min")
    // .replace(/ h.*/, "h")
    // .replace(/ d.*/, "d")
    // .replace(/ mo.*/, "M")
    // .replace(/ y.*/, "Y")
    // .replace(/about/, "");
  }

  return null;
};

// July 12th, 2021
export const prettyDate = (datetime) => {
  if (datetime === undefined || datetime === null) return null;

  return format(datetime * 1000, "PPP");
};

// 02/18/1981
export const formatMDY = (datetime) => {
  if (datetime === undefined) return null;

  return format(datetime * 1000, "M/dd/yyyy");
};

// Jul 12, 2021 at 10:50PM
export const prettyDateAndTime = (datetime) => {
  if (datetime === undefined) return null;

  const date = prettyDateSimple(datetime);
  const time = prettyTime(datetime);
  return `${date} at ${time}`;
};

// expects datetime format of ISO timestamp (Rails)
// example datetime 2021-01-28T14:57:22.000Z
export const prettyDateSimpleISO = (datetime) => {
  if (datetime === undefined) return null;

  // return format(datetime * 1000, "MMM d, yyyy");
  return format(parseISO(datetime), "MMM dd, yyyy");
};

// expects datetime format of Unix timestamp
export const prettyDateSimple = (datetime) => {
  if (datetime === undefined) return null;

  return format(datetime * 1000, "MMM d, yyyy");
};

export const prettyTime = (datetime) => {
  if (datetime === undefined) return null;

  return format(datetime * 1000, "h:mma");
};

// Returns: 10 days, or Future: in 10 days, or Past: 10 days ago
// addSuffix = true, will add the "in" or the "ago"
export const distanceInWords = (date, addSuffix = true) => {
  return formatDistanceToNow(new Date(date), { addSuffix });
};

// src/controllers/subscribe_controller.js
import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import fetch from "isomorphic-fetch";
import queryString from "query-string";
import $ from "cash-dom";

export default class extends Controller {
  static targets = [
    "subscriptionModal",
    "subscriptionForm",
    "checkbox",
    "email",
    "subscriptionSuccessNewIndividual",
    "subscriptionSuccessExistingIndividual",
    "emailConfirmedModal",
    "unsubscribeSuccessModal",
  ];

  connect() {
    console.log("%c[CareersPage subscribe Controller] CONNECTED", "color: #1976D2");
    const parsed = queryString.parse(location.search);
    if (parsed.email_confirmed !== undefined && parsed.email_confirmed === "true") {
      this.showEmailConfirmedModal();
    } else if (parsed.unsubscribed !== undefined && parsed.unsubscribed === "true") {
      this.showUnsubscribedModal();
    }
  }

  // disconnect() {
  //   console.warn('[CareersPage subscribe Controller] dis-connected');
  // }

  /* Unsubscribed Modal
  --===================================================-- */
  showUnsubscribedModal(event) {
    if (event) {
      event.preventDefault();
    }

    $(this.unsubscribeSuccessModalTarget).addClass("subscribe-modal--show");
    $("body").addClass("no-scroll");
  }

  hideUnsubscribedModal(event) {
    if (event) {
      event.preventDefault();
    }

    $(this.unsubscribeSuccessModalTarget).removeClass("subscribe-modal--show");
    $("body").removeClass("no-scroll");

    Turbolinks.visit("/");
  }

  /* Email Confirmed Modal
  --===================================================-- */
  showEmailConfirmedModal(event) {
    if (event) {
      event.preventDefault();
    }

    $(this.emailConfirmedModalTarget).addClass("subscribe-modal--show");
    $("body").addClass("no-scroll");
  }

  hideEmailConfirmedModal(event) {
    if (event) {
      event.preventDefault();
    }

    $(this.emailConfirmedModalTarget).removeClass("subscribe-modal--show");
    $("body").removeClass("no-scroll");

    Turbolinks.visit("/");
  }

  /* Subscription Modal
  --===================================================-- */
  showSubscriptionModal(event) {
    event.preventDefault();
    $(this.subscriptionModalTarget).addClass("subscribe-modal--show");
    $("body").addClass("no-scroll");
    this.emailTarget.focus();
  }

  hideSubscriptionModal(event) {
    event.preventDefault();
    $(this.subscriptionModalTarget).removeClass("subscribe-modal--show");
    $("body").removeClass("no-scroll");

    $(this.subscriptionFormTarget).removeClass("hide");
    $(this.subscriptionSuccessNewIndividualTarget).addClass("hide");
    $(this.subscriptionSuccessExistingIndividualTarget).addClass("hide");
  }

  showSubscriptionSuccess(is_email_confirmed) {
    $(this.subscriptionFormTarget).addClass("hide");

    if (is_email_confirmed) {
      $(this.subscriptionSuccessExistingIndividualTarget).removeClass("hide");
    } else {
      $(this.subscriptionSuccessNewIndividualTarget).removeClass("hide");
    }
  }

  handleSubmitSubscriptionForm(event) {
    event.preventDefault();
    $(this.subscriptionFormTarget).addClass("hide");

    // const url = this.subscriptionFormTarget.url;
    const url = "/api/v1/individual_app/careers_page_subscriptions";
    const email = this.emailTarget.value;
    const careers_page_slug = window.CAREERS_PAGE_SLUG;

    let categories = [];
    this.checkboxTargets.forEach((checkbox) => {
      const name = checkbox.getAttribute("name");
      if (checkbox.checked) {
        categories.push(name);
      }
    });

    const data = {
      careers_page_subscription: {
        email,
        careers_page_slug,
      },
    };

    console.log("%c[CareersPage subscribe] handleSubmitSubscriptionForm", "color: #1976D2", {
      data,
    });

    const that = this;

    fetch(url, {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      credentials: "same-origin",
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log(
          "%c[CareersPage subscribe] handleSubmitSubscriptionForm RESPONSE",
          "color: #1976D2",
          {
            data,
          },
        );
        that.showSubscriptionSuccess(data.is_email_confirmed);
      });
  }
}
